$dark_background_color: #031434;
$mid_background_color: #022A741A;
$light_background_color: #FFF;
$primary_color: #002F87;
$secondary_color: #022A741A;
$drop_shadow_color: rgba(2,42,116,.1);

$section_horizontal_padding: 140px;
$section_vertical_padding: 2em;

$footer_height: 5em;

@import "uikit";
@import '@fortawesome/fontawesome-free/css/all.min';

@import url('https://fonts.googleapis.com/css2?family=Catamaran:wght@400;700;900&display=swap');

* {
	font-family: 'Catamaran', sans-serif;
	color: black;
}

h1 {
 	display: inline-block;
    font-family: 'Catamaran', sans-serif;
    font-weight: 900;
	color: $primary_color;
    text-transform: uppercase;
    font-size: 3em;
	text-decoration: line-through solid $drop_shadow_color 50%;

	@media (max-width: 640px) {
		font-size: 2em;
	}
}

h2 {
	font-family: 'Catamaran', sans-serif;
    font-weight: 700;
	text-transform: uppercase;
    color: $primary_color;
	font-size: 1.25em;
}

p {
	font-family: 'Catamaran', sans-serif;
    font-weight: 400;
    color: black;
	font-size: 1.1em;
}

a {
    font-family: 'Catamaran', sans-serif;
	color: $primary_color;
}

button {
    font-family: 'Catamaran', sans-serif;
}

header {
	background-color: $dark_background_color;
}

nav {
	margin-top: 70px;
	margin-bottom: 35px;
}

#push-footer {
	min-height: calc(100vh - #{$footer_height});
}

body {
	background-color: #F2F4F8;
}

.image-wrapper {
	margin: 3em;

}

footer {
	height: $footer_height;
	background-color: $dark_background_color;
}

.active {
	font-weight: bold;
}

.flex-1 {
	flex: 1;
}

.flex-2 {
	flex: 2;
}

.flex-3 {
	flex: 3;
}

.bottom-margin-lg {
	margin-bottom: 2em;
}

.vertical-margin-md {
	margin-top: 1.5em;
	margin-bottom: 1.5em;
}

.horizontal-margin-md {
	margin-left: 1em;
	margin-right: 1em;
}

.horizontal-margin-sm {
	margin-left: 0.5em;
	margin-right: 0.5em;
}

.block {
	display: block;
}

.main-content {
	max-width: 1200px;
	margin: auto;
}

.header-image {
	background-color: $dark_background_color;
	padding: 5% 5% 0 5%;
	max-height: 100%;
	border-bottom-left-radius: 10px;
	margin-right: -$section_horizontal_padding;
	margin-top: -$section_vertical_padding;
	margin-bottom: 1.5em;
	margin-left: 0;
	box-shadow: -20px -20px 0 0 $secondary_color;
}

.section {
	padding: $section_vertical_padding $section_horizontal_padding;

	@media (max-width: 640px) {
		padding: $section_vertical_padding 10px;
	}
}

.content-column {
	flex: 4 0 0;

	min-width: 20em;
	max-width: 100%;
	padding-bottom: 2em;

	img {
		border-radius: .5em;
		box-shadow: 9px 8px 0 0 $secondary_color;
	}
}

.column-spacer {
	min-width: 30px;
	flex: 1 4 0;
}

.even-section {
	background-color: $light_background_color;
}

.odd-section {
	background-color: $mid_background_color;
}

.uk-button {
	background-color: $primary_color;
	color: #FFF;
	border-radius: 10px;
	box-shadow: 0 7px 0 4px $secondary_color;
	padding: 0.3em 1.5em;
	font-size: 20px;
    font-family: 'Catamaran', sans-serif;
	text-transform: uppercase;
}

.uk-button:hover {
	background-color: $secondary_color;
}

.center-text {
	text-align: center;
}
